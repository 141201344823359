<template>
  <div class="bookmaster-wraper">
    <app-modal-confirm
      ref="apikey_modal_delete"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="deleteApiKey()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common_vn.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <app-modal-confirm
      ref="restaurant_modal_delete"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="deleteRestaurant()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common_vn.delete confirm") }}</p>
      </template>
    </app-modal-confirm>

    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >

      <form class="form-horizontal">
        <div class="row">
          <div class="col-12">
            <div class="card card-body">
              <template v-if="idEditing">
                <div class="form-group row">
                  <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("common.id") }}</label>
                  <label class="col-xl-1 col-lg-2 col-sm-2 m-t-8">{{ entry.id }}</label>
                </div>
              </template>

              <!-- category name -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("category.name") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="kyy_category_name"
                    type="string128"
                    rules="required"
                    v-model="entry.name"
                  />
                </div>
              </div>

              <!-- category name -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("category.name_vn") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-input
                    name="kyy_category_name_vn"
                    type="string128"
                    rules="required"
                    v-model="entry.name_vn"
                  />
                </div>
              </div>

              <!-- Restaurant name -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("restaurant.name") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-select
                    name="restaurant_name"
                    rules="required"
                    :options-data="restaurantList"
                    v-model="entry.restaurant_id"
                  />
                </div>
              </div>

              <!-- image -->
              <div class="form-group row ">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("category.image") }}<span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-single-file-input accept="image/jpeg, image/png, image/jpg" name="kyy_category_image"
                    :imageSrc="entry.image_path"
                    @input="handlerUploadImage"
                    @updated_at="entry.data_updated_at = $event"
                    :id-editing="idEditing"
                    rules="required"
                    :allowedExtension="allowedExtension" >
                  </app-single-file-input>
                  <label v-if="invalid && validated" class="error">
                    {{ errors[0] }}
                  </label>
                </div>
              </div>

              <div class="card-footer row justify-content-end">
                <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="confirmCancel">
                  {{ $t('common_vn.cancel') }}
                </button>
                <button v-if="idEditing" type="button" :disabled="!valid && validated" class="btn btn-danger m-r-10" @click="handleDelete">
                  {{ $t('common_vn.delete') }}
                </button>
                <button v-if="idEditing" :disabled="!valid && validated" class="btn btn-primary m-r-10" @click="createOrUpdate" type="button">
                  {{  $t('common_vn.save') }}
                </button>
                <button v-else :disabled="!valid && validated" class="btn btn-primary m-r-10" type="button" @click="createOrUpdate" >
                  {{  $t('common_vn.create') }}
                </button>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import AppTextarea from "../../../components/forms/AppTextarea";
import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
import {ROUTES} from "@constants";
export default {
  name: "CategoryCreateOrEdit",

  components: {
    AppTextarea,
    AppDateRangePicker,
  },

  data() {
    const id = this.$route.params.id
    return {
      entry: {
        id: null,
        name: '',
        name_vn: '',
        image_path: null,
        image: null,
        data_updated_at: null,
        restaurant_id: null,
        image_updated_at: null
      },
      restaurantList: [],
      apiKeyId: null,
      idEditing: id,
      entryNameUpdate: "",
      startValidate : !!id,
      allowedExtension : ['image/jpeg', 'image/png', 'image/jpg']
    };
  },
  watch: {
  },

  mounted: async function () {
    // call api get list restaurant
    let response = await this.$request.get(this.ENDPOINT.KYY_RESTAURANT_LIST_SELECT);
    if (response.hasErrors()) {
      if (response.status === 'error'){
        this.__noticeError(this.$t(response.data.error_msg));
      }
    } else {
      this.restaurantList = response.data.data.map(item => {
        return {
          id: item.id,
          name: item.name
        }
      });
    }
    if (this.idEditing) {
      this.startValidate = false;
      let response = await this.$request.get(this.ENDPOINT.KYY_CATEGORY_EDIT(this.idEditing));
      if (response.hasErrors()) {
        if (response.status === 'error'){
          this.__noticeError(this.$t(response.data.error_msg));
        }
        await this.redirectDefaultValue()
      } else {
        this.entry = _.cloneDeep(response.data.data);
        this.entry.image_path = this.entry.image.url;
        this.entry.image_updated_at = this.entry.image.updated_at;
        this.startValidate = true;
      }
    }
  },

  methods: {
    handlerUploadImage(data) {
      console.log(1)
      this.entry.image = data
      this.entry.image_path = data
      this.entry.image_updated_at = new Date().getTime()
    },
    confirmCancel()
    {
      if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.BOOK_MASTER_LIST) {
        this.__redirectBack()
      } else {
        this.redirectDefaultValue()
      }
    },
    async createOrUpdate() {
      this.startValidate = true;
      if (!await this.$refs.form_create_entry.validate()) {
        return;
      }
      let res = null;
      let msg = "";
      let params = _.cloneDeep(this.entry);
      if (this.idEditing) {
        res = await this.$request.patch(this.ENDPOINT.KYY_CATEGORY_EDIT(this.idEditing), params);
        msg = this.$t("common.msg update ok")
      } else {
        res = await this.$request.post(this.ENDPOINT.KYY_CATEGORY_CREATE, params);
        msg = this.$t("common.msg create ok")
      }
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        this.__noticeSuccess(msg);
        await this.redirectDefaultValue();
      }
    },

    async redirectDefaultValue() {
      await this.$router.push({
        name: this.ROUTES.ADMIN.KYY_CATEGORY_LIST, query: {}
      });
    },
    handleDelete() {
      this.$refs.restaurant_modal_delete.show();
    },
    async deleteRestaurant() {
      const res = await this.$request.delete(this.ENDPOINT.KYY_CATEGORY_DELETE(this.idEditing));
      if (!res.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg delete ok"));
        await this.redirectDefaultValue();
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        this.$refs.restaurant_modal_delete.hide();
      }
    },
  },
};
</script>
<style>
@media (min-width: 1200px) {
  .col-unit {
    flex: 0 0 14%;
  }
}

</style>
